import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';

const MainOmerta = () => {
  const [videoId, setVideoId] = useState('');

  const getYoutubeEmbedUrl = (videoId) => {
    return `https://www.youtube.com/embed/${videoId}?autoplay=0&rel=0`;
  };

  useEffect(() => {
    const client = createClient({
      space: 'pd1kgur9or06',
      accessToken: 'j_FnIo8DVz3filcvKstiJgR-c9_3MwHeCxJmbgLuUjQ',
    });

    const fetchMainVideo = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'omertaMain',
          select: 'fields.mainVideoUrl',
        });

        if (response.items.length > 0) {
          const mainVideoUrl = response.items[0].fields.mainVideoUrl;
          const videoIdParam = getVideoIdFromUrl(mainVideoUrl);
          setVideoId(videoIdParam);
        }
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    fetchMainVideo();
  }, []);

  const getVideoIdFromUrl = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  };

  return (
    <div className="main  mt-4 overflow-hidden main w-full h-screen text-center mb-0 flex justify-center items-center">
      <div className="inline-block">
        <div className="aspect-w-26 aspect-h-8 w-screen p-4 sm:w-full md:w-full lg:w-[calc(100vw/2)] xl:w-[calc(100vw/2)] relative flex flex-col items-center">
          <div className="aspect-w-16 aspect-h-9 w-full frame mb-4">
            <iframe
              title="Video min-h-[58vh]"
              className="w-full h-full "
              src={getYoutubeEmbedUrl(videoId)}
              allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ minHeight: '48vh' }}
            ></iframe>
          </div>
          <img
            className="w-[3em] mt-8 pointer-events-none invert  "
            src="https://res.cloudinary.com/dzxrdh920/image/upload/v1713972155/almtetn3pd8wsjdiz3kh.png"
            alt=""
          />
        </div>   

      </div>
    </div>
  );
};

export default MainOmerta;
