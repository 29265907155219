import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const Navbar = () => {
  return (
    <div className="navbar top-0 left-116 w-full z-10 bg-black relative">
      <Link to="/">
        <div className="relative w-[19em] mt-16 md:mt-16 lg:mt-0 xl:pt-8 md:w-96 lg:w-[25.2em] xl:w-[25.2em] pt-4 mb-4 mx-auto">
          <img
            className="w-full"
            src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/vdi2ump2f8xvyhp4bltm.png"
            alt="Logo"
          />
         
        </div>
      </Link>

      <ul className="flex flex-wrap justify-center md:hidden space-x-2 mx-auto mr-6">
        <li>
          <Link to="/talent" className="text-white text-md text-[0.950em] pl-4  hover:underline">talent</Link>
          <Icon icon="material-symbols:arrow-insert" />
        </li>
        <li>
          <Link to="/team" className="text-white text-md text-[0.950em] pl-4  hover:underline">team</Link>
          <Icon icon="material-symbols:arrow-insert" />
        </li>
        <li>
          <Link to="/partners" className="text-white text-md text-[0.950em] pl-4  hover:underline">partners</Link>
          <Icon icon="material-symbols:arrow-insert" />
        </li>
        <li>
          <Link to="https://store.end.xn--mk1bu44c/" className="text-white text-md text-[0.950em] pl-4  hover:underline">store</Link>
          <Icon icon="material-symbols:arrow-insert" />
        </li>
      
        <li>
          <Link to="/about" className="text-white text-md text-[0.950em] pl-4  hover:underline">about</Link>
          <Icon icon="material-symbols:arrow-insert" />
        </li>
        
      </ul>

      <ul className="hidden md:flex justify-center items-center space-x-8">
     
      
       
        <li>
          <Link to="/talent" className="text-white text-[1.3em] hover:text-gray-300 hover:underline">talent</Link>
        </li>
        <li>
          <Link to="/team" className="text-white text-[1.3em] hover:text-gray-300 hover:underline">team</Link>
        </li>
        <li>
          <Link to="/partners" className="text-white text-[1.3em] hover:text-gray-300 hover:underline">partners</Link>
        </li>
        <li>
          <Link to="https://store.end.xn--mk1bu44c/" className="text-white text-[1.3em] hover:text-gray-300 hover:underline">store</Link>
        </li>
        <li>
          <Link to="/about" className="text-white text-[1.3em] hover:text-gray-300 hover:underline">about</Link>
        </li>
       
      </ul>

    </div>
  );
}

export default Navbar;
