import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Partners from './Partners';
import PrivacyPolicy from './Privacy-Policy'; 
import TermsOfService from './Terms-Of-Services'; 
import Index from './Index';
import NotFound from './NotFound';
import Talent from './Talent';
import Navbar from './Navbar';
import Footer from './Footer';
import Hero from './Hero';
import About from './About';
import Team from './Team';
import Video from './Video';

const Main = () => {
  const whiteBarStyle = {
    width: '90%',
    height: '1px',
    backgroundColor: 'white',
    margin: '0 auto', 
    marginBottom: '40px', 
  };

  return (
    <div className="main-container w-full h-screen text-center mb-0 justify-center items-center flex-col overflow-y-auto">
      <Navbar />
      <Hero />
      
      <div  style={whiteBarStyle}></div> 
      <h2 className="text-center font-bold  text-6xl text-white">team</h2> 

      <Team />
      <div  style={whiteBarStyle}></div>
      <h1 className='text-white font-bold  text-6xl mb-8 mt-8 text-center '>partners</h1>
      <p className='text-gray-300 pl-3 mt-8 text-[0.9em] lg:text-[1.3em] xl:text-[1.3em] pr-3 lg:pl-56 xl:pl-pr lg:pr-56 xl:pl-pr pb-8 text-center'>
          we extend our deepest appreciation to our partners, investors, and affiliates for their invaluable support.
          <br />
          thank you for being integral to our journey and for sharing our vision of excellence.
        </p>

      <Partners />
      <div  style={whiteBarStyle}></div> 
      
      <About />
      <div  style={whiteBarStyle}></div>
      <div className="header-text font-bold text-white text-6xl  mt-8 justify-center text-center mb-16">talent</div>

      <Talent />
      
      <Footer />
    </div>
  );
};

export default Main;
