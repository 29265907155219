import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const Privacy = () => {
  const [termsAndPrivacy, setTermsAndPrivacy] = useState({ endPrivacyPolicy: null, endTermsOfServices: null });

  useEffect(() => {
    const client = createClient({
      space: 'pd1kgur9or06',
      accessToken: 'j_FnIo8DVz3filcvKstiJgR-c9_3MwHeCxJmbgLuUjQ',
    });

    const fetchTermsAndPrivacy = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'endTermsAndPrivacy',
          select: 'fields.endPrivacyPolicy,fields.endTermsOfServices',
        });

        if (response.items.length > 0) {
          const { endPrivacyPolicy, endTermsOfServices } = response.items[0].fields;
          setTermsAndPrivacy({ endPrivacyPolicy, endTermsOfServices });
        }
      } catch (error) {
        console.error('Error fetching terms and privacy content:', error);
      }
    };

    fetchTermsAndPrivacy();
  }, []);

  const renderRichText = (richText) => {
    const options = {
      renderText: (text) =>
        text.split('\n').flatMap((text, i) => [
          i > 0 && <br key={i} />,
          text
        ]),
    };

    return documentToReactComponents(richText, options);
  };

  return (
    <div className="terms-and-privacy-container" style={{ minHeight: '100vh', width: '100%', flexDirection: 'column' }}>

      <div className='flex text-white gap-8 text-4xl p-2'>
      </div>

      <div style={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p className='text-white text-[14px] lg:text-[20px] pl-12 pr-12 p-8 lg:pl-80 lg:pr-80'>{termsAndPrivacy.endTermsOfServices &&
          renderRichText(termsAndPrivacy.endTermsOfServices)}</p>
      </div>
    </div>
  );
};

export default Privacy;
