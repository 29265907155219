import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white text-black py-6 px-6 flex flex-col items-center lowercase">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full">
        <div className="flex justify-center md:justify-start mb-2 md:mb-0 md:mr-4">
          <div className="h-12 w-12 rounded-full flex items-center justify-center mr-2"><Link to="https://end.xn--mk1bu44c/" className=" hover:opacity-90 transform hover:scale-110">
          <img
            className="w-7 h-7"
            src="https://res.cloudinary.com/dzxrdh920/image/upload/v1713972155/almtetn3pd8wsjdiz3kh.png"
            alt="Link 1"
          />
        </Link></div>
  
          <div className=" h-12 w-12 rounded-full flex items-center justify-center"> <Link to="https://ihateomerta.com/" className="  hover:opacity-90 transform hover:scale-110">
          <img
            className="yama w-11 h-11 ml-1 z-9"
            src="https://res.cloudinary.com/dzxrdh920/image/upload/v1713972500/drqkt3a6ppbf7zzvhoqf.png"
            alt="Link 2"
          />
        </Link></div>
        </div>
        <div className="text-[1em] lg:text-[1.350em] xl:text-[1.350em] mb-2 md:mb-0 text-center w-full">Copyleft <span class="inline-block transform scale-x-[-1]">&#169;</span> 2024 Every Night Dawns, All Rights Reversed<br></br>
        <a href="/privacy-policy" className="text-black mr-2 lg:mr-8 xl:mr-8 hover:underline"><span className='text-base'>Privacy Policy</span></a>
        <span className="text-gray-200">|</span>
        <a href="/terms-of-service" className="text-black ml-2 lg:ml-8 xl:ml-8 hover:underline"><span className='text-base'>Terms of Service</span></a>
      </div>
        
        
        <div className="flex justify-center md:justify-end w-full md:w-auto">
          <div className="h-12 w-12 rounded-full flex items-center justify-center mr-2 mt-1">
          <Link to="https://instagram.com/everynightdawns">
            <Icon icon="mdi:instagram" className="text-black text-3xl hover:opacity-90 transform hover:scale-110"/>
            </Link></div>
          <div className="h-12 w-12 rounded-full flex items-center justify-center mr-2 mt-1">
          <Link to="https://x.com/everynightdawns">   <Icon icon="pajamas:twitter" className="text-black text-3xl hover:opacity-90 transform hover:scale-110"/>

          </Link> </div>
          <div className="h-12 w-12 rounded-full flex items-center justify-center mr-2 mt-1">  <Link to="https://facebook.com/everynightdawns">        <Icon icon="mdi:facebook" className="text-black text-3xl hover:opacity-90 transform hover:scale-110"/>
          </Link> </div>
         
        </div>
      </div>
     
    </footer>
  );
};

export default Footer;
