import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Main from './components/Main';
import Partners from './components/Partners';
import PrivacyPolicy from './components/Privacy-Policy'; 
import TermsOfServices from './components/Terms-Of-Services'; 
import Index from './components/Index';
import NotFound from './components/NotFound';
import Talent from './components/Talent';
import About from './components/About';
import Team from './components/Team';
import Video from './components/Video';
import Hero from './components/Hero';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== '/' && <Navbar />}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/terms-of-service" element={<TermsOfServices />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/Index" element={<Index />} />
        <Route path="/team" element={<Team />} />
        <Route path="/about" element={<About />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/talent" element={<Talent />} />
        <Route path="/video" element={<Video />} />
        <Route path="/hero" element={<Hero />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname !== '/' && <Footer />}
    </div>
  );
}

export default App;
