import React from 'react';
import { Link } from 'react-router-dom';


const Talent = ({ headerText, imageUrl }) => {
  return (
    <div className="header-container flex flex-col items-center justify-center h-screen">
      <div className="image-container h-screen mt-16 flex flex-col items-center">
        <a className='hover:opacity-30' href="https://ihateomerta.com">
          <img className="header-image omerta-logo w-[20em] lg:w-[30 xl:w-[30em]" src="https://blowedout.s3.sa-east-1.amazonaws.com/Omerta/Logo/Omerta+-+Logo.png" alt="Header Image" />
        </a>
        <h5 className='text-white text-7xl text-center mt-8 '>????</h5>        <h5 className='text-white text-7xl text-center mt-8 '>????</h5>

      </div>
      <div className="footer-container w-full ">
      </div>
    </div>
  );
};

export default Talent;
