import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';

const Team = () => {
  const [talents, setTalents] = useState([]);

  useEffect(() => {
    const client = createClient({
      space: 'pd1kgur9or06',
      accessToken: 'j_FnIo8DVz3filcvKstiJgR-c9_3MwHeCxJmbgLuUjQ',
    });

    const fetchTalents = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'endTalent',
          select: 'fields.talentName,fields.talentRole',
        });
        const sortedTalents = response.items.sort((a, b) => {
          if (a.fields.talentName < b.fields.talentName) return -1;
          if (a.fields.talentName > b.fields.talentName) return 1;
          return 0;
        });
        setTalents(sortedTalents);
      } catch (error) {
        console.error('Error fetching talents:', error);
      }
    };

    fetchTalents(); 
  }, []); 

  return (
    <div className='flex justify-center items-start h-screen'>
      <div className="w-full p-4 h-1/2 mt-16 w-screen lg:w-2/3"> 
        <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-10 mt-6">
          {talents.map((talent) => ( 
            <div key={talent.sys.id} className="p-0 lg:p-4 xl:p-4 rounded-md text-center">
              <div className="text-[1em] lg:text-[1.5em] xl:text-[1.5em]  font-bold mb-2 text-white">{talent.fields.talentName}</div>
              <div className="text-xs lg:text-[1em] xl:text-[1em] text-gray-600">{talent.fields.talentRole}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
