import React from 'react';

const Partners = () => {
  return (
<div class="block lg:flex xl:flex justify-center  items-center h-screen">
      <div>
       
        <div className="flex justify-center">


          <div className="hidden lg:grid xl:grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 ml-14 gap-0 max-w-4xl mx-auto">

               <a className="hover:opacity-30" target="blank_" href="https://dansim.org"><div className='invert w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40 hover:opacity-30' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714961783/i8zv3219svol37tigfjf.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
          <a href="https://segye.xyz" target="blank_">  <div className='w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40 invert hover:opacity-30' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1716675418/Segye/uktryxwi0hle1fmkpsry.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div></a>

  
       <a className="hover:opacity-30 mb-16" target="blank_" href="https://imugi.co">     <div className='w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40 hover:opacity-30' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714446739/bm7aogyfufc92pu5zuxc.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
  </a>  <a href="https://sijagsteel.com/" target="blank_">      <div className='w-[7em] h-[7em] lg:h-32 lg:w-24 xl:h-40 xl:w-24 hover:opacity-30 ml-8' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714446745/c7igy8tay7i2pcy1mwsf.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div></a>

 
          <a className="ml-16 hover:opacity-30" target="blank_" href="https://hybecorp.com/eng/main">            <div className='w-[7em] h-[7em] lg:h-32 w-48 invert xl:h-40 xl:w-48' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/xl9qhdu8cz4ygavlgax0.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a><a className="ml-32  hover:opacity-30" target="blank_" href="https://dahn.world">            <div className='w-[7em] h-[7em] lg:h-32 lg:w-28 xl:h-40 xl:w-28' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/qynx3lj5qyv7riu8olb5.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
          <a className="ml-20 hover:opacity-30" target="blank_" href="https://www.cosanostrapr.com/">            <div className='w-[7em] h-[7em] lg:h-32 lg:w-72 xl:h-40 xl:w-72' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714453628/yjnqxofs5kytniucszow.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
           <div className='opacity-0 mt-10 ml-32 w-[7em] h-[7em] lg:h-32 lg:w-16 xl:h-40 xl:w-16' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/qynx3lj5qyv7riu8olb5.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
  <a className="hover:opacity-30 ml-[8.5em]" target="blank_" href="https://blowedoutrecords.com/">            <div className='w-[7em] h-[7em] mt-8 lg:h-32 ml-8 lg:w-[30em] invert xl:h-40 xl:w-[30em] mt-0' style={{ backgroundImage: 'url(https://blowedout.s3.sa-east-1.amazonaws.com/logo2.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
          </div>






<div className="grid  lg:hidden xl:hidden grid grid-cols-2 mt-8 lg:grid-cols-4 xl:grid-cols-4 gap-8 max-w-4xl mx-auto">

               <a className="hover:opacity-30" target="blank_" href="https://dansim.org"><div className='invert w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40 hover:opacity-30 ' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714961783/i8zv3219svol37tigfjf.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a><a className="hover:opacity-30 invert " target="blank_" href="https://blowedoutrecords.com/">            <div className='w-[7em] h-[7em]  lg:h-32 lg:w-[30em] invert xl:h-40 xl:w-[30em]' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1715724116/BOR%20Assets/zezrkdzhrklxmwtim5tm.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
          
    <a href="https://sijagsteel.com/" target="blank_">      <div className='w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40 hover:opacity-30' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714446745/c7igy8tay7i2pcy1mwsf.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div></a><a className="hover:opacity-30" target="blank_" href="https://dahn.world">            <div className='w-[5em] h-[5em] ml-5 lg:h-32 lg:w-32 xl:h-40 xl:w-40' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/qynx3lj5qyv7riu8olb5.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>

       <a className="hover:opacity-30" target="blank_" href="https://imugi.co">     <div className='w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40 hover:opacity-30' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714446739/bm7aogyfufc92pu5zuxc.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
  </a><a href="https://segye.xyz" target="blank_">  <div className='w-[7em] h-[7em] mt-2 lg:h-32 invert lg:w-32 xl:h-40 xl:w-40 hover:opacity-30' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1716675418/Segye/uktryxwi0hle1fmkpsry.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div></a>

          <a className="hover:opacity-30" target="blank_" href="https://hybecorp.com/eng/main">            <div className='w-[7em] h-[7em] lg:h-32 w-32 invert xl:h-40 xl:w-40' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/xl9qhdu8cz4ygavlgax0.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
          <a className="hover:opacity-30" target="blank_" href="https://www.cosanostrapr.com/">            <div className='w-[7em] h-[7em] lg:h-32 lg:w-32 xl:h-40 xl:w-40' style={{ backgroundImage: 'url(https://res.cloudinary.com/dzxrdh920/image/upload/v1714453628/yjnqxofs5kytniucszow.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}></div>
</a>
      
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default Partners;
