import React from 'react';


const About = () => {
  return (
    <div className='block'>
      <div className="block lg:flex xl:flex flex-col h-screen mr-8 ml-8 md:flex-row md:items-center justify-center">
        <img
          src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714453967/ow27maw7zq5weuyxug5x.png"
          alt=""
          className="md:mr-8 pointer-events-none ml-auto mr-auto mb-4 md:mb-0 lg:ml-32 w-[15em] h-[15em] lg:w-[25em] lg:h-[25em] xl:w-[25em] xl:h-[25em]"
        />
        <div className="block lg:flex xl:flex flex-col items-start md:items-end md:text-right">
          <p className="text-gray-300 text-center pl-8 pr-8 mt-16 mb-8 lg:mb-0 xl:mb-0 lg:pl-16 lg:pr-24 xl:pl-16 xl:pr-24 text-[0.8em] lg:text-[1.2em] xl:text-[1.2em] text-center md:text-left">
            <h2 className='text-white text-4xl lg:text-6xl xl:text-6xl font-bold  mb-8 mt-8'>about</h2>
            나무는 끝이 시작이다. 언제나 끝에서 시작한다. 실뿌리에서 잔가지 우듬지 새순에서 꽃 열매에 이르기까지 나무는 전부 끝이 시작이다.
            <br />
            <br />
            지금 여기가 맨 끝이다. 나무 땅 물 바람 햇빛도 저마다 모두 맨 끝이어서 맨 앞이다. 기억 그리움 고독 절망 눈물 분노도 꿈 희망 공감 연민 연대도 사랑도 역사 시대 문명 진화 지구 우주도 지금 여기가 맨 앞이다.
            <br />
            <br />
            지금 여기 내가 정면이다.
          </p>
          <img
            className="w-16 pointer-events-none h-16  lg:w-28 lg:h-28 ml-auto mr-auto mt-4 md:ml-0 md:mr-64 md:mt-0"
            src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714455689/uigh1jmh9l6k22nlqnhz.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default About;
