import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Partners from './Partners';
import PrivacyPolicy from './Privacy-Policy'; 
import TermsOfServices from './Terms-Of-Services'; 
import Index from './Index';
import NotFound from './NotFound';
import Talent from './Talent';
import Navbar from './Navbar';
import Footer from './Footer';

import About from './About';
import Team from './Team';
import Video from './Video';

const Main = () => {
  return (
    <div className="main-container w-full h-screen text-center mb-0  justify-center items-center flex-col overflow-y-auto">
          <Navbar />

          <Team />

      <Partners />
      <About />

      <Footer />
    </div>
  );
};

export default Main;
